import {
  getTicketById,
  getTickets,
  getTwoHubUpdates,
  getTicketSerialNumber,
  getTicketHistory,
  getTicketFiles,
  deleteTicketFile,
} from '@/api/tickets';
import { QUERIES } from '@/config/query-keys';
import { useQuery } from '@tanstack/react-query';


export function useTickets(search: string, pageIndex: number, filterVariable: string, sortBy?: any[]) {
  return useQuery({
    queryKey: QUERIES.TICKETS(search, pageIndex, filterVariable, sortBy),
    queryFn: () => getTickets(search, pageIndex, filterVariable, sortBy),
    keepPreviousData: true,
    staleTime: Infinity,
  });
}

export function useTicketById(id: string) {
  return useQuery({
    queryKey: QUERIES.TICKET(id),
    queryFn: () => getTicketById(id),
    enabled: !!id,
  });
}

export function useTwoHubUpdates(id) {
  return useQuery({
    queryKey: QUERIES.UPDATES(id),
    queryFn: () => getTwoHubUpdates(id),
  });
}

export function useTicketSerial(serial: string, options?: any) {
  return useQuery({
    queryKey: QUERIES.TICKET(serial),
    queryFn: () => getTicketSerialNumber(serial),
    ...options,
  });
}

export function useTicketHistory() {
  return useQuery({
    queryKey: QUERIES.TICKET_HISTORY,
    queryFn: () => getTicketHistory(),
  });
}

export function useTicketFiles(companyId: string, ticketId: string, searchQuery?: string, options?: any) {
  return useQuery({
    queryKey: QUERIES.TICKET_FILES(companyId, ticketId, searchQuery),
    queryFn: () => getTicketFiles(companyId, ticketId, searchQuery),
    ...options,
  });
}
export function useTicketDelete(userId: string, ticketid: string, fileId: string) {
  return useQuery({
    queryKey: QUERIES.TICKET_FILES(userId, ticketid, fileId),
    queryFn: () => deleteTicketFile(userId, ticketid, fileId),
  });
}
