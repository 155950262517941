import { SUPABASE_KEYS } from '@/config/supabase-keys';
import { supabase } from '@/utils/supabase/client';

const PAGE_SIZE = 10;

const getTickets = async (
  searchQuery: string,
  pageIndex: number,
  filterVariable: string,
  sortBy: any[],
): Promise<any> => {
  const range = pageIndex ? pageIndex - 1 : 0;
  const offset = range * PAGE_SIZE;
  let query = supabase.from(SUPABASE_KEYS.TICKETS).select('*', { count: 'exact' });

  const filterActions = {
    Open: () => query.is('closed_date', null),
    Completed: () => query.not('closed_date', 'is', null),
  };
  if (filterVariable && filterActions.hasOwnProperty(filterVariable)) {
    query = filterActions[filterVariable]();
  }

  if (searchQuery) {
    query = query.ilike('subject', `%${searchQuery}%`);
  }

  if (offset) {
    query = query.range(offset, offset + PAGE_SIZE - 1);
  }

  if (sortBy) {
    if (sortBy.length > 0) {
      query = query.order(sortBy[0].id, {
        ascending: sortBy[0].desc,
      });
    } else {
      query = query.not('createdate', 'is', null).order('createdate', { ascending: false });
    }
  }

  const { data, count } = await query.limit(10);

  const PAGE_COUNT = Math.ceil(count / PAGE_SIZE);

  return {
    data: data ?? [],
    pageCount: PAGE_COUNT,
  };
};

const getTicketById = async (id: string): Promise<any> => {
  const { data } = await supabase.from(SUPABASE_KEYS.TICKETS).select().eq('id', id).single();

  return data;
};

const getTwoHubUpdates = async (id: string) => {
  const { data } = await supabase.functions.invoke('two-hub-updates', {
    body: { hs_object_id: Number(id) },
  });

  return data;
};

const getTicketSerialNumber = async (serial: string): Promise<any> => {
  const { data, error } = await supabase.from(SUPABASE_KEYS.TICKETS).select('*').eq('item_serial_number', serial);

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

const createTicket = async (values: any): Promise<any> => {
  const { data, error } = await supabase.from('tickets').insert(values);

  if (error) {
    throw new Error(error.message);
  }
  return data;
};

const getTicketHistory = async (): Promise<any> => {
  const { data, error } = await supabase
    .from(SUPABASE_KEYS.TICKETS)
    .select('*')
    .order('createdate', { ascending: false })
    .limit(3);

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

const getTicketFiles = async (companyId: string, ticketId: string, searchQuery?: string): Promise<any> => {
  let query = supabase.storage.from('uploads').list(`${companyId}/tickets/${ticketId}`, {
    limit: 10,
    offset: 0,
    search: searchQuery,
  });

  const { data, error } = await query;
  if (error) {
    throw new Error(error.message);
  }

  return data;
};

const deleteTicketFile = async (companyId: string, ticketid: string, fileName: string): Promise<void> => {
  const { data, error } = await supabase.storage
    .from(`uploads`)
    .remove([`${companyId}/tickets/${ticketid}/${fileName}`]);

  if (error) {
    throw new Error(error.message);
  }
};
const downloadTicketFile = async (companyId: string, ticketid: string, fileName: string): Promise<void> => {
  const { data, error } = await supabase.storage
    .from(`uploads`)
    .download(`${companyId}/tickets/${ticketid}/${fileName}`);

  if (error) {
    throw new Error(error.message);
  }
  const href = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(href);
};

export {
  getTickets,
  getTicketById,
  getTwoHubUpdates,
  getTicketSerialNumber,
  createTicket,
  getTicketHistory,
  getTicketFiles,
  deleteTicketFile,
  downloadTicketFile,
};
