import { TextInput } from '@mantine/core';
import React from 'react';

export interface InputProps {
  label?: string;
  value?: string;
  name?: string;
  type?: string;
  placeholder?: string;
  icon?: React.ReactElement;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: string;
  radius?: string;
  error?: any;
}

export const CustomInput: React.FC<InputProps> = ({
  label,
  value,
  placeholder,
  icon,
  setValue,
  name,
  type,
  onChange,
  error,
  size = 'lg',
  radius = 'md',
  ...restProps
}) => {
  return (
    <TextInput
      label={label}
      error={error}
      value={value}
      placeholder={placeholder}
      width="100%"
      rightSection={icon && icon}
      onChange={onChange}
      name={name || ''}
      type={type ? type : 'text'}
      radius={radius}
      variant="filled"
      size={size}
      {...restProps}
      styles={{
        input: {
          border: 'none',
          background: '#F2F2F2',
        },
      }}
    />
  );
};
